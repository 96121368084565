<template>
  <div
    class="relative"
    :style="style"
    v-bind="attr"
  >
    <img
      v-if="style.backgroundImage"
      :src="style.backgroundImage"
      class="h-full w-full object-cover"
    />
    <iframe
      v-else-if="youtubeFrame"
      :src="youtubeFrame"
      class="mn:absolute h-[300px] w-full md:h-full"
      title="Video Frame"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { type Element as ElementType } from '../../types/model';

const props = defineProps({
  style: { type: Object, default: () => ({}) },
  value: { type: String, default: '' },
  attr: { type: Object as PropType<ElementType<'video'>['attr']>, default: () => ({}) }
});

const youtubeFrame = computed(() => {
  if (!props.value) return '';
  const youtubeID = youtubeParser(props.value);
  return `https://www.youtube.com/embed/${youtubeID}`;
});

function youtubeParser(url: string) {
  if (!url) return '';
  const isShortURL = /\.be\//.test(url);
  let youtubeID = '';
  if (isShortURL) {
    youtubeID = url.split('/').slice(-1)[0];
  } else {
    youtubeID = url.split('v=')[1];
  }
  const ampersandPosition = youtubeID?.indexOf('&');
  if (ampersandPosition != -1) {
    youtubeID = youtubeID?.substring(0, ampersandPosition);
  }
  return youtubeID;
}
</script>
